.App-header {
  min-height: 100vh;
  background-color: black;
}

.ImageSection {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto;
}

.Packages {
  text-align: center;
  min-height: 100vh;
  height: fit-content;
  color: white;
  padding-bottom: 40px;
}

.white-stroke {
  color: transparent;
  -webkit-text-stroke: 0.7px white;
}
.yellow-stroke {
  color: transparent;
  -webkit-text-stroke: 0.7px  #e7c376;
}
.blue-stroke {
  color: transparent;
  -webkit-text-stroke: 0.7px  #96eff9;
}