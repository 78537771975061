@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Blackout";
  src: url("/public/fonts/BlackoutSunrise.ttf");
}

@font-face {
  font-family: "Russo";
  src: url("/public/fonts/RussoOne-Regular.ttf");
}

@font-face {
  font-family: "SpaceAge";
  src: url("/public/fonts/space-age.ttf");
}

@font-face {
  font-family: "Arial";
  src: url("/public/fonts/arial_narrow.ttf");
}

html {
  color: white;
  background-color: black;
  height: 100%;
  font-family: Helvetica, sans-serif
}

body {
  height: 100%;
  overflow: auto;
}

img {
  max-width: unset;
}
